const config = {
  API_SERVER_URL: 'http://localhost:4001',
  VIDEO_THUMBNAILS_PATH: 'http://localhost:4001/video-thumbnails'
};

if (process.env.REACT_APP_PROD) {
  config.API_SERVER_URL = '/';
  config.VIDEO_THUMBNAILS_PATH = '/video-thumbnails';
}

export { config }
