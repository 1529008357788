import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
  TagOutlined,
  AppstoreOutlined,
  FileOutlined,
} from '@ant-design/icons';

import MemoService from "../services/memo.service";

import "./StatisticMenu.scss";

const {SubMenu} = Menu;

const DEFAULT_AGGREGATIONS = {
  categories: {buckets: []},
  tags: {buckets: []},
  nesting: {keywords: {buckets: []}}
};

interface StatisticMenuProps {
  search: (searchKey: string, term: string) => void
}

function StatisticMenu({search}: StatisticMenuProps) {
  const [aggregations, setAggregations] = useState<any>(DEFAULT_AGGREGATIONS);

  const fetchData = async () => {
    const data: any = await MemoService.aggregateMemo();
    setAggregations(data.aggregations);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Menu
      className="statistic-menu"
      defaultSelectedKeys={['tags']}
      defaultOpenKeys={['categories', 'tags', 'keywords']}
      mode="inline"
      theme="dark"
    >
      {/*Categories*/}
      <SubMenu
        key="categories"
        title={
          <span>
            <AppstoreOutlined/>
            <span>Categories</span>
          </span>
        }
      >
        {aggregations.categories.buckets.map((bucket: any) => <Menu.Item onClick={() => search('category', bucket.key)}
                                                                         key={`category_${bucket.key}`}>{bucket.key} ({bucket.doc_count})</Menu.Item>)
        }
      </SubMenu>

      {/*Tags*/}
      <SubMenu
        key="tags"
        title={
          <span>
            <TagOutlined/>
            <span>Tags</span>
          </span>
        }
      >
        {aggregations.tags.buckets.map((bucket: any) => <Menu.Item onClick={() => search('tag', bucket.key)}
                                                                   key={`tag_${bucket.key}`}>{bucket.key} ({bucket.doc_count})</Menu.Item>)
        }
      </SubMenu>

      {/*Keywords*/}
      <SubMenu
        key="keywords"
        title={
          <span>
            <FileOutlined/>
            <span>Keywords</span>
          </span>
        }
      >
        {aggregations.nesting.keywords && aggregations.nesting.keywords.buckets.map((bucket: any) => <Menu.Item
          onClick={() => search('keyword', bucket.key)}
          key={`keyword_${bucket.key}`}>{bucket.key} ({bucket.doc_count})</Menu.Item>)
        }
      </SubMenu>
    </Menu>
  )
}

export default React.memo(StatisticMenu)
