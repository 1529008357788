import React from "react";
import { List } from "antd";

import "./SearchResults.scss";
import SearchResult from "./SearchResult";

interface SearchResultsProps {
  results: any
  gotoPage: (page: number) => void
  currentPage: number,
  onPlayVideo: (videoId: string, startAt: number, title: string) => void
  onHideVideo: () => void
  navigateTo: (pathname: string, search: string) => void
  changeTitleTo: (title: string) => void
  isLoading: boolean
}

function SearchResults(props: SearchResultsProps) {
  return (
    <List
      itemLayout="vertical"
      bordered
      size="large"
      className="search-results"
      pagination={{
        onChange: page => {
          props.gotoPage(page);
        },
        current: props.currentPage,
        pageSize: 10,
        total: props.results.total,
        position: "both",
        showTotal: function (total) {
          return `Total ${total} items`
        },
        responsive: true
      }}
      loading={props.isLoading}
      dataSource={props.results.results}
      renderItem={(item: any) => (
        <List.Item>
          <SearchResult result={item} {...props}/>
        </List.Item>
      )}
    />
  );
}

export default SearchResults;
