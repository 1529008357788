import React from "react";
import { List, Tag, Typography, Collapse } from "antd";

import { config } from "../configs";

import "./SearchResult.scss";

const {Title, Paragraph} = Typography;
const {Panel} = Collapse;

interface SearchResultProps {
  result: any,
  onPlayVideo: (videoId: string, startAt: number, title: string) => void
  onHideVideo: () => void
  navigateTo: (pathname: string, search: string) => void
  changeTitleTo: (title: string) => void
}

function SearchResult(props: SearchResultProps) {
  const item = props.result;

  const onMainResultClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const title = item.mainResult.source.title;
    props.navigateTo(`/watch/${item.id}`, `?s=${0}`);
    props.changeTitleTo(title);
    props.onPlayVideo(item.id, 0, title);
  };

  const onSubResultClick = (e: any, subResult: any) => {
    e.preventDefault();
    e.stopPropagation();

    const startAt = extractStartAt(subResult);
    const title = item.mainResult.source.title;
    props.onPlayVideo(item.id, startAt, title);
    props.navigateTo(`/watch/${item.id}`, `?s=${startAt}`);
    props.changeTitleTo(title);
  };

  const extractStartAt = (subResult: any) => {
    const startAt = Math.max(0, parseInt(subResult.key.split("_")[1]) - 1);
    return startAt;
  }

  return (
    <div className="search-result">
      {/*Main matched result*/}
      <a href={`/watch/${item.id}?s=0`} className="main-result" onClick={onMainResultClick}>
        <div className="thumbnail-container">
          <img src={item.mainResult.source.thumbnail_url} className="thumbnail" alt="main"/>
        </div>
        <div className="info-container">
          <Typography>
            <Title level={4}>{item.mainResult.source.title}</Title>
            <Paragraph className="description">
              {item.mainResult.source.description}
            </Paragraph>
            <Paragraph>
              Source: {item.mainResult.source.source}
            </Paragraph>
          </Typography>

          {/*Tags*/}
          {item.mainResult.source.tags &&
          <div>
            {item.mainResult.source.tags.map(((tag: any) => <Tag key={tag}>{tag}</Tag>))}
          </div>
          }
        </div>
      </a>

      {/*Sub matched result*/}
      {(item.subResults && item.subResults.length > 0) &&
      <Collapse defaultActiveKey={['1']} className="collapser">
        <Panel header="Sub matches" key="1">
          <List
            className="sub-results"
            dataSource={item.subResults}
            renderItem={(subResult: any) => (
              <List.Item>
                <a href={`/watch/${item.id}?s=${extractStartAt(subResult)}`} className="sub-result"
                   onClick={(e) => onSubResultClick(e, subResult)}>
                  <div className="thumbnail-container">
                    <img src={`${config.VIDEO_THUMBNAILS_PATH}/${item.id}/${item.id}_${subResult.key}.jpg`}
                         alt="sub"
                         className="thumbnail"/>
                  </div>
                  <div className="info-container">
                    {/*Matched key words*/}
                    {subResult.word &&
                    <div>
                      {subResult.word.map(((tag: any) => <Tag key={tag}>{tag}</Tag>))}
                    </div>
                    }

                    {/*Matched timestamps*/}
                    {subResult.text &&
                    <div>
                      <span>{subResult.text}</span>
                    </div>
                    }
                  </div>
                </a>
              </List.Item>
            )}/>
        </Panel>
      </Collapse>
      }
    </div>
  );
}

export default SearchResult;
