import React, { useState, useEffect, useRef } from "react";
import { Layout, Modal } from "antd";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BackTop } from 'antd';
import YouTube, { PlayerVars } from 'react-youtube';

import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import StatisticMenu from "../components/StatisticMenu";
import MemoService from "../services/memo.service";

import AppLogo from '../statics/images/logo.png';
import "./Home.scss";

const {Header, Footer, Sider, Content} = Layout;

const PLAYER_VARS: PlayerVars = {
  autoplay: 1
};

const DEFAULT_YOUTUBE_OPTS = {
  height: '390',
  width: '100%',
  playerVars: PLAYER_VARS
};

let globalSearchKey = '';
let globalSearchTerm = '';

function Home(props: RouteComponentProps) {
  const [searchResults, setSearchResults] = useState({total: 0, results: []});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlayerVisible, setPlayerVisible] = useState(false);
  const [playerOpts, setPlayerOpts] = useState(DEFAULT_YOUTUBE_OPTS);
  const [playingVideoId, setPlayingVideoId] = useState();
  const [playingVideoTitle, setPlayingVideoTitle] = useState();
  const backTop = useRef(null);

  const pathname = props.location.pathname;
  const searchPath = props.location.search;
  const params = new URLSearchParams(searchPath);
  const term = params.get('term') || '';
  const category = params.get('category') || '';
  const tag = params.get('tag') || '';
  const keyword = params.get('keyword') || '';

  const handleSearchResponse = (searchResponse: any) => {
    const results = searchResponse.hits.hits.map((hit: any) => {
      const keywordHits = (hit.inner_hits && hit.inner_hits.keywords) ? hit.inner_hits.keywords.hits.hits.map((hit: any) => hit._source) : [];
      const timestampHits = (hit.inner_hits && hit.inner_hits.timestamps) ? hit.inner_hits.timestamps.hits.hits.map((hit: any) => hit._source) : [];

      return {
        id: hit._id,
        total: searchResponse.hits.total.value,
        mainResult: {source: hit._source, highlight: hit.highlight || {}},
        subResults: timestampHits.concat(keywordHits)
      }
    });

    const resp = {
      total: searchResponse.hits.total.value,
      results
    };

    setSearchResults(resp);
  };

  const searchByPage = async (page: number) => {
    setCurrentPage(page);
    await search(globalSearchKey, globalSearchTerm, page);
    if (backTop && backTop.current) {
      // @ts-ignore
      backTop.current.scrollToTop();
    }
  };

  const search = async (searchKey: string, term: string, page = 1) => {
    if (term.length === 0) return;
    setIsLoading(true);
    if (globalSearchKey !== searchKey || globalSearchTerm !== term) {
      setCurrentPage(1);
    }
    globalSearchKey = searchKey;
    globalSearchTerm = term;
    const searchResponse: any = await MemoService.searchMemo({[searchKey]: term, page});
    handleSearchResponse(searchResponse);
    searchCallback(searchKey, term);
    setIsLoading(false);
  };

  const searchCallback = (searchKey: string, term: string) => {
    if (searchKey !== 'top') {
      const pathname = '/search';
      const search = `?${searchKey}=${term}`;
      navigateTo(pathname, search);
      changeTitleTo(term);
    }
  };

  const onPlayVideo = (videoId: string, startAt: number, title: string) => {
    const newPlayerOpts = JSON.parse(JSON.stringify(DEFAULT_YOUTUBE_OPTS));
    newPlayerOpts.playerVars.start = startAt;
    setPlayerOpts(newPlayerOpts);
    setPlayingVideoId(videoId);
    setPlayingVideoTitle(title);
    setPlayerVisible(true);
  }

  const onHideVideo = () => {
    setPlayerVisible(false);
    if (props.history.location.key) {
      props.history.goBack();
    } else {
      navigateTo("/", "");
    }
  }

  const handleClearSearchTerm = () => {
    setSearchResults({total: 0, results: []});
  };

  const navigateTo = (pathname: string, search: string) => {
    props.history.push({pathname, search});
  };

  const changeTitleTo = (title: string) => {
    document.title = `${title} | Videos Life Hacks`;
  };

  useEffect(() => {
    if (term) {
      search('term', term);
    } else if (category) {
      search('category', category);
    } else if (tag) {
      search('tag', tag);
    } else if (keyword) {
      search('keyword', keyword);
    } else {
      // default search
      search('top', 'top');

      if (pathname.startsWith("/watch/")) {
        const videoId = pathname.split("/")[2];
        const startAt = parseInt(params.get('s') || "0");
        onPlayVideo(videoId, startAt, 'Videos Life Hacks');
      }
    }
  }, []);

  return (
    <div className="home">
      <BackTop ref={backTop}/>
      <Layout className="main-layout">
        <Sider
          breakpoint="md"
          collapsedWidth="0"
          onBreakpoint={broken => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
        >
          <div className="logo-container">
            <a href="/">
              <img src={AppLogo} className="logo"/>
            </a>
          </div>
          {/*<Avatar size={32} src={AppIcon}/>*/}
          <StatisticMenu search={search}/>
        </Sider>
        <Layout>
          <Header className="app-header">
            {/*Search Bar*/}
            <SearchForm
              term={term}
              onClear={handleClearSearchTerm}
              search={search}
            />
          </Header>
          <Content>
            {/*Search Result*/}
            <SearchResults results={searchResults} gotoPage={searchByPage} currentPage={currentPage}
                           onPlayVideo={onPlayVideo} onHideVideo={onHideVideo} navigateTo={navigateTo}
                           changeTitleTo={changeTitleTo} isLoading={isLoading}/>
          </Content>
          <Footer style={{textAlign: 'center'}}>© Copyright 2020 by Phieudu241</Footer>
        </Layout>
      </Layout>

      {isPlayerVisible &&
      <Modal
        title={playingVideoTitle}
        closable={true}
        visible={isPlayerVisible}
        width={690}
        centered={true}
        onCancel={onHideVideo}
        footer={null}
      >
        <YouTube
          videoId={playingVideoId}
          opts={playerOpts}
        />
      </Modal>
      }
    </div>
  );
}

export default withRouter(Home);
