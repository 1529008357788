import { message } from "antd";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { config } from "../configs";

axios.defaults.baseURL = config.API_SERVER_URL;

class Requester {
  requester: AxiosInstance;

  constructor() {
    let axiosInstance = axios.create({
      headers: {'Content-Type': 'application/json'}
    });

    axiosInstance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.requester = axiosInstance;
  }

  handleSuccess(response: AxiosResponse) {
    return response.data;
  }

  handleError = (error: AxiosError) => {
    // @ts-ignore
    // switch (error.response.status) {
    //   case 401:
    //     this.redirectTo(document, '/');
    //     break;
    //   case 404:
    //     this.redirectTo(document, '/404');
    //     break;
    // }

    message.error("There was an error when requesting to server");

    return Promise.reject(error);
  };

  redirectTo = (document: any, path: string) => {
    document.location = path;
  };

  get(url: string, params?: any, config?: AxiosRequestConfig) {
    return this.requester.get(url, Object.assign({}, {params}, config));
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.requester.post(url, data, config);
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.requester.put(url, data, config);
  }

  patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.requester.patch(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.requester.delete(url, config);
  }
}

let API = new Requester();

export default API;
