import Memo from "../models/memo";
import API from "./api";

const MEMO_URI = '/memos';

const createMemo = (memo: Memo) => {
  return API.post(MEMO_URI, memo);
};

const updateMemo = (memo: Memo) => {
  return API.put(MEMO_URI, memo);
};

const deleteMemo = (memoId: string) => {
  return API.delete(`${MEMO_URI}/${memoId}`);
};

const getMemo = (memoId: string) => {
  return API.get(`${MEMO_URI}/${memoId}`);
};

const searchMemo = (params: any) => {
  return API.get(MEMO_URI, params);
};

const suggestMemo = (params: any) => {
  return API.get(`${MEMO_URI}/suggestions`, params);
};

const aggregateMemo = () => {
  return API.get(`${MEMO_URI}/aggregations`);
};

export default {
  getMemo,
  createMemo,
  updateMemo,
  deleteMemo,
  searchMemo,
  suggestMemo,
  aggregateMemo
}


